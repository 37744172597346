import React from "react"
import { Layout } from "../../components/Layout"
import { OrganisationsSection } from "../../style/organisations"
import NewOrganisation from "../../components/NewOrganisation"

function newOrganisation(props) {

  return (
    <div>
      <Layout>
        <OrganisationsSection>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <p
              onClick={() => {
                navigate("/organisations")
                // ;((location.state.data.isPrimary === true ||
                //   location.state.data.isAgent === true) &&
                //   typeof history !== "undefined" &&
                //   history.go(-1)) ||
                //   (typeof history !== "undefined" && history.go(-2))
              }}
              style={{
                cursor: "pointer",
                // textDecoration: "underline",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Organisations
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.9rem",
                marginBottom: ".9rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {" "}
              {/* &#8827; */}/
            </p>
            {/* {typeof location.state !== `undefined` &&
              location.state.data.isPrimary === undefined && (
                <>
                  <p
                    onClick={() => {
                      typeof history !== "undefined" && history.go(-1)
                    }}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "0.7rem",
                      marginBottom: "1rem",
                    }}
                  >
                    
                  </p>
                  <p
                    style={{
                      // cursor: "pointer",
                      fontSize: "0.9rem",
                      marginBottom: ".9rem",
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    {" "}
                    &#8827;
                  </p>
                </>
              )} */}
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Add New Organisation
            </p>
          </div>
          <h1>Add New Organisation</h1>
          <NewOrganisation />
        </OrganisationsSection>
      </Layout>
    </div>
  )
}

export default newOrganisation
