import React, { useState, useEffect } from "react"
import { useDispatch, connect } from "react-redux"
import { addNewOrganisation, fetchOrganisationTypes } from "../state"
import { AddForm } from "../style/globalStyles"
import Switch from "react-switch"

const NewOrganisation = ({ organisationTypes }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({})
  const [enable, setEnable] = useState(false)
  console.log(state)

  useEffect(() => {
    dispatch(fetchOrganisationTypes());
  }, [])

  const createOptions = (inputArray) => {
    console.log("inputArray", inputArray)
    return inputArray.map((type) => (
      <option value={Number(type.lookupId)} key={type.lookupId} name="organisationType">
        {type.lookupDescription}
      </option>
    ))
  }

  const handleChangeOrganisationType = (e) => {
    setState({ ...state, [e.target.name]: Number(e.target.value) })
    setEnable(true)
  }

  const handleChange = (e) => {
    console.log(e)
    setState({ ...state, [e.target.name]: e.target.value })
    setEnable(true)

    console.log("inside hc", state)
  }
  const handleActiveChange = (value) => {
    console.log(value)
    setState({ ...state, isActive: value })
    setEnable(true)

    console.log("inside hac", state)
  }
  const handleVerifiedChange = (value) => {
    console.log(value)
    setState({ ...state, isVerified: value })
    setEnable(true)

    console.log("inside hvc", state)
  }
  const handleSubscribeChange = (value) => {
    console.log(value)
    setState({ ...state, isSubscription: value })
    setEnable(true)

    console.log("inside hsc", state)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    // dispatch(addNewOrganisation(state))
    console.log("new organisation", state)
  }
  return (
    <AddForm onSubmit={handleSubmit}>
      <div>
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <label htmlFor="organisationName">
            Organisation Name
            <input
              id="organisationName"
              name="organizationName"
              type="text"
              placeholder={"Organisation name"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="organisationAddress">
            Address
            <input
              id="organisationAddress"
              name="organizationAddress"
              type="text"
              placeholder={"Organisation address"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="organisationCity">
            City
            <input
              id="organisationCity"
              name="organizationCity"
              type="text"
              placeholder={"Organisation city"}
              onChange={handleChange}
            />
          </label>
          {/* <label htmlFor="organisationState">
            State
            <input
              id="organisationState"
              name="organizationState"
              type="text"
              placeholder={"Organisation state"}
              onChange={handleChange}
            />
          </label> */}
          {/* <label htmlFor="pincode">
            Pincode
            <input
              id="pincode"
              name="pincode"
              type="text"
              placeholder={"Organisation pincode"}
              onChange={handleChange}
            />
          </label> */}
          <label htmlFor="organisationCountry">
            Country
            <input
              id="organisationCountry"
              name="organizationCountry"
              type="text"
              placeholder={"Organisation country"}
              onChange={handleChange}
            />
          </label>
          {/* <label htmlFor="mobileNo">
            Mobile Number
            <input
              id="mobileNo"
              name="mobileNo"
              type="text"
              placeholder={"Oobile number"}
              onChange={handleChange}
            />
          </label> */}

          <p />
        </div>
        <br />
        <br />
        <div
          style={{
            marginBottom: "3rem",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <label htmlFor="organisationType">
            Organisation Type
            <select
              style={{
                backgroundColor: "none",
                borderColor: "gray",
                fontSize: ".9rem",
                height: "2rem",
                margin: ".5rem 0",
              }}
              name="organisationType"
              id="organisationType"
              defaultValue={0}
              onChange={(e) => handleChangeOrganisationType(e)}
            >
              <option>Select organisation type</option>
              {createOptions(organisationTypes)}
            </select>
          </label>
          <label htmlFor="organisationDiscount">
            Organisation Discount
            <input
              id="organisationDiscount"
              name="organizationDiscount"
              type="text"
              placeholder={"Organisation discount"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="startDate">
            Start Date
            <input
              id="startDate"
              name="startDate"
              type="date"
              placeholder={"yyyy-dd-mm"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="endDate">
            End Date
            <input
              id="endDate"
              name="endDate"
              type="date"
              placeholder={"yyyy-dd-mm"}
              onChange={handleChange}
            />
          </label>
        </div>
      </div>
      <div style={{ marginBottom: "0%", alignContent: "flex-end" }}>
        {/* <label htmlFor="isActive">
          Active Status
          <br />
          <br />
          <Switch
            name="isActive"
            className="react-switch"
            onChange={handleActiveChange}
            checked={state.isActive}
          />
        </label> */}
        {/* <label htmlFor="isVerified">
          Verified Status
          <br />
          <br />
          <Switch
            name="isVerified"
            className="react-switch"
            onChange={handleVerifiedChange}
            checked={state.isVerified}
          />
        </label> */}
        {/* <label htmlFor="isSubscription">
          Subscription Status
          <br />
          <br />
          <Switch
            name="isSubscription"
            className="react-switch"
            onChange={handleSubscribeChange}
            checked={state.isSubscription}
          />
        </label> */}
        <br />
        <button
          type="submit"
          aria-disabled={enable ? true : false}
          style={{
            borderRadius: "5px",
            backgroundColor: enable ? "green" : "lightgray",
            cursor: enable ? "pointer" : "default",
          }}
        >
          Add Organisation
        </button>
      </div>
    </AddForm>
  )
}

const mapStateToProps = (state) => ({
  organisationTypes: state.organisations.organisationTypes
})

export default connect(mapStateToProps)(NewOrganisation)
